import { MenuEntry } from '@globalswapx/uikit'


const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://globalswap.exchange'
  },
  {
    label: 'Exchange',
    href: 'https://swap.globalswap.exchange/#/swap',
    icon: "ExchangeIcon"
  },
  {
    label: 'Liquidity',
    href: 'https://swap.globalswap.exchange/#/pool',
    icon: 'LiquidityIcon'
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: 'https://globalswap.exchange/farms'
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: 'https://globalswap.exchange/pools'
  },
  
  {
    label: 'Launchpad (IDOs)',
    icon: 'IdoIcon',
    href: '',
  },
  {
    label: 'Price Graph',
    icon: 'GraphIcon',
    href: '',
  },
  {
    label: 'Buy GBS',
    icon: 'InfoIcon',
    href: 'https://cryptosale.finance',
  },
  {
    label: 'Staking GBS',
    icon: 'InfoIcon',
    href: 'https://staking.globalswap.exchange',
  },
  {
    label: 'Listings',
    icon: 'ListingIcon',
    items: [
      {
        label: 'CoinMarketCap',
        href: 'https://coinmarketcap.com/currencies/',
      },
      {
        label: 'CoinGecko',
        href: 'https://www.coingecko.com/',
      },
    ],
  },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: 'https://www.globalswap.exchange/lottery'
  // },
  // {
  //   label: 'Info',
  //   icon: 'InfoIcon',
  //   items: [
  //     {
  //       label: 'SaltSwap',
  //       href: 'https://saltswap.info/token/TODO',
  //     },
  //   ]
  // },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      // {
      //   label: 'Voting',
      //   href: 'https://voting.saltswap.finance',
      // },
      {
        label: "Github",
        href: "https://github.com/globalswapx/",
        icon: 'GithubIcon'
      },
      {
        label: "Docs",
        href: "https://globalswap-exchange.gitbook.io/globalswap/",
        icon: 'GitbookIcon'
      },
      // {
      //   label: "Blog",
      //   href: "https://saltswap.medium.com",
      //   icon: 'MediumIcon'
      // },
    ],
  },
]



export default config
